<template>
  <div class="Site PageBox">
    <NavBar :title="$t('common3[1]')" :page-number="-1"/>
    <div class="titlePanel">
      <div style="width: 40%;margin-top: 16px;">
        <img style="margin-left:48px; width: 61px;height: 78px;" src="~@/assets/img/skinbitcoin/logo1.png" alt="">
      </div>
      <div style="width: 60%;font-size:21px;color:#fff;text-align:left;opacity:1; margin-top: auto;margin-bottom: auto;height: 50%;">
        {{$t('common3[1]')}}
      </div>
    </div>
    <!--<div class="ScrollBox" style="margin-right: 15px;width: 95%;padding: 10px">
        <div style="text-align: justify;" v-html="textContent"></div>
    </div>-->
    <div style="display: flex; flex-direction: column; align-items: center;">
      <div><img src="~@/assets/img/skinbitcoin/8.png" style="width: 375px;"></div>

      <!--start-->
      <div style="position: relative;">
        <div style="display: flex; flex-direction: column; align-items: center;">
          <div><img src="~@/assets/img/skinbitcoin/12611.png"></div>
          <div style="position: absolute; top: 9px; font-size: 15px; font-weight: bold; color: var(--font_subtitle);">{{$t('help.default[0]')}}<!--成立BITCOIN公司的理由--></div>
        </div>
      </div>
      <div class="border-grey" style="width:346px; height: 197px; font-size: 11px; padding: 12px; overflow: auto;">
        <div style="margin-top: 8px;">
          {{$t('help.default[1]')}}<!--據報道，宣布絕對禁止的國家和地區包括阿爾及利亞、孟加拉、中國、埃及、伊拉克、摩洛哥、尼泊爾、卡達和突尼斯。 然而，對於這個龐大的市場，全球各大交易所仍放不下這些市場，於是第三方經紀平台BITCOIN誕生了。-->
        </div>
        <div style="margin-top: 18px;">
          <div>{{$t('help.default[2]')}}<!--BITCOIN第三方經紀平台後端:--></div>
          <div>{{$t('help.default[3]')}}<!--BITCOIN第三方經紀平台，經Coinbase、Binance、Crypto等各大交易所認可創建。--></div>
          <div>{{$t('help.default[4]')}}<!--第三方經紀平台獲利模式:--></div>
          <div>
            {{$t('help.default[5]')}}<!--在禁止登入或註冊整合BITCOIN第三方經紀平台的國家，無法透過自己的交易所購買加密貨幣。 在禁止整合BITCOIN第三方經紀平台的國家，需要購買加密貨幣的人主要註冊BITCOIN第三方經紀平台。 成為商業商戶用戶，透過BITCOIN平台下單購買加密貨幣，並根據訂單的需求和數量對每筆交易收取費用。-->
          </div>
        </div>
      </div>
      <!--end-->

      <!--start-->
      <div style="position: relative; margin-top: 20px;">
        <div style="display: flex; flex-direction: column; align-items: center;">
          <div><img src="~@/assets/img/skinbitcoin/12611.png"></div>
          <div style="position: absolute; top: 9px; font-size: 15px; font-weight: bold; color: var(--font_subtitle);">{{$t('help.default[6]')}}<!--平台會員收入模式--></div>
        </div>
      </div>
      <div class="border-grey" style="width:346px; height: 468px; font-size: 11px; padding: 12px; margin-bottom: 40px;overflow: auto;">
        <div style="margin-top: 8px; ">
          {{$t('help.default[7]')}}<!--成為BITCOIN平台會員後，您可以透過各大交易所購買加密貨幣並在BITCOIN平台上出售。BITCOIN平台上出售的單價會比交易所上出售的3%-10%的利潤點高。 例如，你可以透過upbit、Coinone、coinbase等任意交易所購買1000USDT，透過BITCOIN平台賣給被禁國家的人，賺取3-10%的利潤。 這意味著您可以賺取 30-100USDT。-->
        </div>
        <div style="margin-top: 18px;">
          <div>{{$t('help.default[8]')}}<!--模式說明--></div>
          <div>{{$t('help.default[9]')}}<!--模式一：平台自動搭配銷售訂單，依會員VIP等級搭配訂單金額及利潤比例。 以及每天的銷售訂單數量。--></div>
          <div>{{$t('help.default[10]')}}<!--例如：--></div>
          <div>{{$t('help.default[11]')}}<!--VIP1每天可以自動配對銷售訂單，賺取銷售額的3%作為訂單收入。--></div>
          <div>{{$t('help.default[12]')}}<!--VIP2每天可以自動配對並銷售2個訂單，並透過這些訂單賺取銷售額的5%--></div>
          <div>{{$t('help.default[13]')}}<!--VIP3每天可以自動撮合3次賣單，您可以獲得賣出金額的7%作為賣出利潤。--></div>
          <div>{{$t('help.default[14]')}}<!--VIP4每天可自動撮合4次賣單，可賣出9%的賣出金額。--></div>
          <div>{{$t('help.default[15]')}}<!--VIP5每天可以自動配對並銷售5個訂單，並從這些訂單中賺取10%的銷售額。--></div>
          <div>{{$t('help.default[16]')}}<!--模式二：團購--></div>
          <div>
            {{$t('help.default[17]')}}
            <!--全球加盟商每天在BITCOIN大廳購買的加密貨幣數量不同，每個加盟商的獲利點也不同。 有的加盟商為了緊急採購給予很高的利潤點。
            當一些商家急於收購時，利潤點相對高於自動匹配的銷售收入。 重點是團賣訂單沒有限制，每天都可以做。
            只要有賣家可以買，平台會員就可以賣。-->
          </div>
          <div>{{$t('help.default[18]')}}<!--模式三：套利--></div>
          <div>{{$t('help.default[19]')}}<!--套利交易是全球投資者與我們的人工智慧系統合作，提供全球貨幣之間的套利交易服務。--></div>
          <div>{{$t('help.default[20]')}}<!--是一個引進人工智慧AI自動匹配各地區投資者交易系統，隨時可以獲利的程式。
            一個自動交易程序，這是一個利用馬丁的交易技術進行交易來賺取利潤的程序。-->
          </div>
        </div>
      </div>
      <!--end-->

      <!--start-->
      <div style="position: relative;">
        <div style="display: flex; flex-direction: column; align-items: center;">
          <div><img src="~@/assets/img/skinbitcoin/12611.png"></div>
          <div style="position: absolute; top: 9px; font-size: 15px; font-weight: bold; color: var(--font_subtitle);">{{$t('help.default[21]')}}<!--公司簡介--></div>
        </div>
      </div>
      <div class="border-grey" style="width:346px; height: 197px; font-size: 11px; padding: 12px; overflow: auto; margin-bottom: 40px;">
        <div style="margin-top: 8px;">
          {{$t('help.default[22]')}}
          <!--
          我們專注加密貨幣十餘年，只提供安全、穩定、有獲利的服務。 我們擁有20多位頂級財富管理管家。
          隨著時間的推移而快速變化的邏輯不應被視為簡單地創建或測試底層。 透過代幣經紀交易實現新飛躍的標準系統。
          -->
        </div>
      </div>
      <!--end-->
    </div>
    <van-popup v-model="showCon" position="bottom" closeable close-on-popstate  style="width: 100%;height: 100%;background-color: #fff">
      <div class="ScrollBox" style="padding: 16px 20px;">
        <h3 style="text-align: center;margin-bottom: 20px">{{infoData.title}}</h3>
        <div class="Content" style="text-align: justify;" v-html="infoData.content"></div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'Help',
  components: {
  },
  props: [],
  data() {
    return {
      showCon: false,
      infoData:{title:'',content:''},
      textContent:''
    }
  },
  computed: {

  },
  watch: {

  },
  created() {
    let siteDesc = this.InitData.siteDesc;
    if (this.InitData.siteDesc){
      this.infoData = this.InitData.siteDesc[0];
      for (var i=0;i<50;i++){
        this.infoData.content = this.infoData.content.replace("nowrap","");
      }
      this.textContent = this.infoData.content;
    }
    // this.infoData.content = "</p><p><img src=\"/upload/image/20220808/1659944902225667.jpg\" title=\"1659944902225667.jpg\" alt=\"images (1).jpg\"/>";
	  // let aa=JSON.parse(this.InitData.helpList[0].content)
	  // this.InitData.helpList.map(item=>JSON.parse(item.content))
	console.log(this.InitData.helpList)
  },
  mounted() {

  },
  activated() {

  },
  destroyed() {

  },
  methods: {
    openShow(data) {
      this.showCon = true
      this.infoData = data
    }
  }
}
</script>
<style scoped>
  .PageBox {

  }

.Content>>>img{
  max-width: 100%;
}

.ScrollBox{
	padding:0 35px;
}
.ScrollBox>>>.van-cell{
	padding:20px 5px;
	border-bottom: 1px solid #D9D9D9;
}
.ScrollBox>>>.van-cell:last-child{
	border-bottom: 0px solid #D9D9D9;
}
.ScrollBox>>>.van-cell::after{
	display: none;
}
.ScrollBox>>>.van-icon-chat-o::before{
	content: '';
	border-radius: 50%;
	background: #B3B5FC;
	width:8px;
	height: 8px;
}
.ScrollBox>>>.van-icon-chat-o{
	display: flex;
	align-items: center;
	justify-content: center;
}
.ScrollBox>>>.van-cell__title{
	font-size: 16px;
	font-family: ui-serif;
}
.ScrollBox>>>h3{
	font-size: 1.05rem;
	font-family: emoji;
}
.van-cell>>>.van-icon{
	font-size: 18px;
	color:#333;
}
.PageBox>>>.van-popup__close-icon--top-right{
	color:#333;
	top:20px;
	right: 20px;
}
.PageBox>>>.Content{
	margin-top:10px;
}
.PageBox>>>.Content p{
	font-size: 13px;
	line-height: 1.7;
	font-family: 'vant-icon';
	font-weight: 400;
}

  .titlePanel {
    margin: 10px;
    border-radius: 10px;
    line-height: 30px;
    padding: 20px;
    font-size: 17px;
    display: flex;
    flex-direction: row;

    /* 设置圆角渐变 */
    border: 2px solid transparent;
    border-radius: 10px;
    background-clip: padding-box, border-box;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(to right, var(--panel_color), var(--panel_color)), linear-gradient(to right,#f0cf03,#0099ff);
  }
</style>
